import { useMutation, useQueryClient } from "react-query";
import { Auth } from "../../interface";
import { mutatePostFn } from "../../services/networking.service";
import ENDPOINTS from "../../services/endPoints";
export const useAddCubicleDetails = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutatePostFn(ENDPOINTS.ADD_CUBICLE_DETAIL, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};
