import React from "react";

type Props = {
  children?: React.ReactNode;
  loader?: any;
};

const CustomLaoder: React.FC<Props> = ({ children, loader }) => {
  function Loading() {
    return (
      <div className="position-absolute top-50 start-50 translate-middle">
        <div
          className="spinner-grow"
          style={{
            width: "100px",
            height: "100px",
            backgroundColor: "#72BCB8",
          }}
          role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  return <div>{loader ? <Loading /> : children}</div>;
};

export default CustomLaoder;
