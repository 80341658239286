import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/content_wrapper/header";
import { useGetProfile } from "../../hooks/profileApi/getProfile";
import { useFormik } from "formik";
import { useUpdateProfile } from "../../hooks/profileApi/updateProfile";
import { toast } from "react-toastify";
import {
  ChangePasswordInitialSchema,
  ChangePasswordinitialValues,
  editProfileValues,
  editProfileValuesSchema,
} from "../../validations";
import CustomLaoder from "../../components/customLoader";
import { useAdminChangePassword } from "../../hooks/profileApi/changePassword";

interface ShowPassword {
  oldPassword: boolean;
  newPassword: boolean;
  confirmPassword: boolean;
}
const Profile: React.FC = () => {
  const [editEmail, setEditEmail] = useState(true);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState<ShowPassword>({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const { data, isLoading } = useGetProfile();
  const { mutateAsync } = useUpdateProfile();
  const { mutateAsync: PasswordAsync } = useAdminChangePassword();
  const handleEditEmail = () => {
    setEditEmail(!editEmail);
  };
  const acceptedImageTypes = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  useEffect(() => {
    if (!selectedImage) return;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      setPreviewImage(e.target.result as string);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    if (!acceptedImageTypes.test(file.name)) {
      toast.error("Invalid file type. Please select an image file.");
      return;
    }
    setSelectedImage(file);
  };
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: editProfileValues,
    validationSchema: editProfileValuesSchema,
    onSubmit: async () => {
      const formData: any = new FormData();
      formData.append("name", values.name);
      formData.append("image", selectedImage);
      try {
        const res: any = await mutateAsync(formData);
        if (res?.status === true) {
          toast.success(res?.message);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
  });

  useEffect(() => {
    if (data?.data) {
      setFieldValue("name", data?.data?.name);
    }
  }, [data?.data]);

  const handleShowPassword = (field: keyof ShowPassword) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [field]: !prevShowPassword[field],
    }));
  };
  const {
    values: AdminValues,
    touched: AdminTouched,
    errors: AdminErrors,
    handleBlur: AdminHandleBlur,
    handleChange: AdminHandleChange,
    handleSubmit: AdminHandleSubmit,
    resetForm: AdminResetForm,
  } = useFormik({
    initialValues: ChangePasswordinitialValues,
    validationSchema: ChangePasswordInitialSchema,
    onSubmit: async () => {
      try {
        const res = await PasswordAsync({
          old_password: AdminValues.oldPassword,
          new_password: AdminValues.confirmPassword,
        });
        if (res?.message) {
          toast.success(res?.message);
          AdminResetForm();
          const closeModelButton: any = document.getElementById("close-btn");
          closeModelButton.click();
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
  });

  return (
    <div className="main-wrapper">
      <Header title="Profile" />
      <CustomLaoder loader={isLoading}>
        <div className="content-wrapper">
          <section className="main-section profile-section spacer">
            <div className="container">
              <div className="row gy-3">
                <div className="col-xl-10">
                  <form>
                    <div className="row justify-content-start g-3">
                      <div className="col-xxl-12">
                        <div className="profile-main-wrapper">
                          {!editEmail && (
                            <input
                              type="file"
                              id="uploadprofile"
                              className="d-none"
                              onChange={handleImageChange}
                            />
                          )}
                          <div className="d-flex justify-content-between ">
                            <label
                              htmlFor="uploadprofile"
                              className="profile-img">
                              <img
                                src={
                                  previewImage ||
                                  (data?.data?.image ??
                                    "assets/images/icons/dummy-user.png")
                                }
                                alt="Profile"
                              />
                              {!editEmail && (
                                <span>
                                  <i className="fa-solid fa-image"></i>
                                  Upload Profile
                                </span>
                              )}
                            </label>
                            <div>
                              <span
                                className="reset-password"
                                data-bs-toggle="modal"
                                data-bs-target="#changePasswordModal">
                                Change Password
                              </span>
                            </div>
                          </div>

                          <div className="profile-details">
                            <div className="row gy-4">
                              <div className="col-lg-6">
                                <div className="sub-heading mt-0 mb-3">
                                  <h2 className="h4 mb-0">Full Name</h2>
                                </div>
                                <div className="form-block p-relative">
                                  <input
                                    readOnly={editEmail ? true : false}
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    type="text"
                                    className="form-control"
                                    placeholder="Full Name"
                                  />
                                  {touched.name && errors.name && (
                                    <p className="error">{errors.name}</p>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="sub-heading mt-0 mb-3">
                                  <h2 className="h4 mb-0">Email Address</h2>
                                </div>
                                <div className="form-block p-relative">
                                  <input
                                    readOnly
                                    type="text"
                                    className="form-control"
                                    placeholder="Email"
                                    value={data?.data?.email}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="profile-btns">
                                  <a
                                    onClick={handleEditEmail}
                                    className="btn primary-border-btn">
                                    Edit Profile
                                  </a>
                                </div>
                              </div>
                              <div className="col-xxl-6 align-self-end">
                                <div className="profile-btns">
                                  <button
                                    disabled={editEmail}
                                    type="button"
                                    className="btn primary-btn me-0 w-100"
                                    onClick={() => {
                                      handleSubmit();
                                      handleEditEmail();
                                    }}>
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </CustomLaoder>
      {/* <!-- change password Modal --> */}
      <div
        className="modal common-modal fade"
        id="changePasswordModal"
        tabIndex={-1}
        aria-labelledby="changePasswordLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                id="close-btn"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="common-box-body">
                <h2>Change Password</h2>
                <div className="common-modal-form">
                  <div className="common-modal-field">
                    <label htmlFor="oldPassword">Old Password</label>
                    <div className="p-relative">
                      <input
                        type={showPassword.oldPassword ? "text" : "password"}
                        placeholder="Please Enter Old Password"
                        className="form-control"
                        value={AdminValues.oldPassword}
                        onBlur={AdminHandleBlur}
                        onChange={AdminHandleChange}
                        name="oldPassword"
                        id="oldPassword"
                      />
                      {AdminTouched.oldPassword && AdminErrors.oldPassword && (
                        <p className="error">{AdminErrors.oldPassword}</p>
                      )}
                      <span
                        id="eye"
                        onClick={() => handleShowPassword("oldPassword")}>
                        <i
                          className={
                            showPassword.oldPassword
                              ? "fa-solid fa-eye"
                              : "fa-solid fa-eye-slash"
                          }
                          id="eyeIcon1"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="common-modal-field">
                    <label htmlFor="newPassword">New Password</label>
                    <div className="p-relative">
                      <input
                        placeholder="Enter New Password"
                        type={showPassword.newPassword ? "text" : "password"}
                        className="form-control"
                        value={AdminValues.newPassword}
                        onBlur={AdminHandleBlur}
                        onChange={AdminHandleChange}
                        name="newPassword"
                        id="newPassword"
                        defaultValue={1234567}
                      />
                      {AdminTouched.newPassword && AdminErrors.newPassword && (
                        <p className="error">{AdminErrors.newPassword}</p>
                      )}
                      <span
                        id="eye"
                        onClick={() => handleShowPassword("newPassword")}>
                        <i
                          className={
                            showPassword.newPassword
                              ? "fa-solid fa-eye"
                              : "fa-solid fa-eye-slash"
                          }
                          id="eyeIcon2"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="common-modal-field">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <div className="p-relative">
                      <input
                        placeholder="Confirm Password"
                        type={
                          showPassword.confirmPassword ? "text" : "password"
                        }
                        className="form-control"
                        value={AdminValues.confirmPassword}
                        onBlur={AdminHandleBlur}
                        onChange={AdminHandleChange}
                        name="confirmPassword"
                        id="confirmPassword"
                      />
                      {AdminTouched.confirmPassword &&
                        AdminErrors.confirmPassword && (
                          <p className="error">{AdminErrors.confirmPassword}</p>
                        )}
                      <span
                        id="eye"
                        onClick={() => handleShowPassword("confirmPassword")}>
                        <i
                          className={
                            showPassword.confirmPassword
                              ? "fa-solid fa-eye"
                              : "fa-solid fa-eye-slash"
                          }
                          id="eyeIcon3"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="common-modal-btns">
                  <button
                    className="btn primary-btn me-0"
                    onClick={() => AdminHandleSubmit()}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profile;
