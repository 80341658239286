import { useMutation, useQueryClient } from "react-query";
import { Auth } from "../../interface";
import { mutatePutFn } from "../../services/networking.service";
import ENDPOINTS from "../../services/endPoints";

export const useOwnerResetPassword = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutatePutFn(ENDPOINTS.OWNER_RESET_PASSWORD, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};
