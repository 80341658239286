import { useState } from "react";
import "./App.css";
import userGlobalConfig from "./constants/globalConfig";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./services/networking.service";
import { AuthContext } from "./context/authContext";
import { LanguageProvider } from "./context/languageContext";
import { ToastContainer, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "animate.css/animate.min.css";

import Navigation from "./routes";
import { SidebarProvider } from "./context/sidebarContext";
const bounce = cssTransition({
  enter: "animate__animated animate__bounceIn",
  exit: "animate__animated animate__bounceOut",
});

const App = () => {
  const [isAuthenticated, setisAuthenticated] = useState(
    localStorage.getItem(userGlobalConfig.TOKEN) ? true : false
  );

  return (
    <QueryClientProvider client={queryClient}>
      <LanguageProvider>
        <SidebarProvider>
          <AuthContext.Provider value={{ isAuthenticated, setisAuthenticated }}>
            <Navigation />
            <ToastContainer
              transition={bounce}
              position="top-right"
              autoClose={1500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable="mouse"
              pauseOnHover
              theme="light"
            />
          </AuthContext.Provider>
        </SidebarProvider>
      </LanguageProvider>
    </QueryClientProvider>
  );
};

export default App;
