import * as Yup from "yup";

const addPlanValues = {
  title: "",
  price: "",
  monthType: "",
  type: "",
};

//  validations schemas
const addPlanValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required*"),
  price: Yup.number().required("Price is required*"),
  type: Yup.string().required("Type is required*"),
  monthType: Yup.string(),
});
const addMonthValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required*"),
  price: Yup.number().required("Price is required*"),
  type: Yup.string().required("Type is required*"),
  monthType: Yup.string().required("Time Period is required*"),
});
const secondComponentValidationSchema = Yup.object().shape({
  price: Yup.number().required("Price is required*"),
});
const combinedValidationSchema = addPlanValidationSchema.concat(
  secondComponentValidationSchema
);
export { addPlanValues, combinedValidationSchema, addMonthValidationSchema };
