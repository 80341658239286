import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import userGlobalConfig from "../constants/globalConfig";
import englishTranslation from "./englishTranslation.json";
import hindiTranslation from "./hindiTranslation.json";

const getUserLanguage = () => {
  const storedLanguage = localStorage.getItem(userGlobalConfig.LANGUAGE);
  return storedLanguage || "en";
};

const resources = {
  en: {
    translation: englishTranslation,
  },

  hn: {
    translation: hindiTranslation,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: getUserLanguage(),
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

// Set the user's preferred language in local storage
i18n.on("languageChanged", (lng: string) => {
  localStorage.setItem(userGlobalConfig.LANGUAGE, lng);
});

export default i18n;
