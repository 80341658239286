import React, { useState } from "react";
import PropTypes from "prop-types";

interface Props {
  currentPage: number;
  totalPages: number;
  handleNextPage: (page: number) => void;
  handlePrevPage: (page: number) => void;
  handleRowLimitChange: (value: number) => void;
}

const Pagination: React.FC<Props> = (props) => {
  const {
    currentPage,
    totalPages,
    handlePrevPage,
    handleNextPage,
    handleRowLimitChange,
  } = props;
  const [rowLimit, setRowLimit] = useState(5);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowLimit(Number(event.target.value));
    handleRowLimitChange(Number(event.target.value));
  };
  return (
    <div className="pagination-button-wrapper">
      <label className="row-label">
        <span className="row-title">Rows per page</span>
        <select
          value={rowLimit}
          onChange={handleChange}
          className="form-select">
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={40}>40</option>
        </select>
      </label>
      <button
        className="pagination-button"
        onClick={() => handlePrevPage(currentPage)}
        disabled={currentPage === 1}>
        <i className="fa-solid fa-chevron-left"></i>
      </button>

      <span className="pagination-page-info">
        Page {currentPage} of {totalPages}
      </span>

      <button
        className="pagination-button"
        onClick={() => handleNextPage(currentPage)}
        disabled={currentPage === totalPages}>
        <i className="fa-solid fa-chevron-right"></i>
      </button>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handlePrevPage: PropTypes.func.isRequired,
  handleNextPage: PropTypes.func.isRequired,
  handleRowLimitChange: PropTypes.func.isRequired,
};

export default Pagination;
