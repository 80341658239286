import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../context/sidebarContext";
import { useGetProfile } from "../../hooks/profileApi/getProfile";

interface HeaderProps {
  title: string;
}
const Header: React.FC<HeaderProps> = ({ title }) => {
  const { toggleSidebar } = useContext(SidebarContext);
  const { data } = useGetProfile();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/profile");
  };
  const handleNotification = () => {
    navigate("/notifications");
  };

  return (
    <header>
      <nav className="navbar-expand-sm navbar-light">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-2 col-sm-1 d-xxl-none">
              <button
                className="opnBtn"
                type="button"
                style={{ display: "inline-block" }}
                onClick={toggleSidebar}>
                <i className="fa-solid fa-bars" />
              </button>
            </div>
            <div className="col-7">
              <span className="page-heading">{title}</span>
            </div>
            <div className="col-3 col-sm-4 col-sm-4">
              <div className="header-menu">
                <ul className="navbar-nav justify-content-end ms-auto mb-0 navsbar">
                  {/* <li className="nav-item">
                    <a className="nav-link" onClick={handleNotification}>
                      <svg
                        width={24}
                        height={28}
                        viewBox="0 0 24 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.9073 25.2147L14.8847 25.2304L14.863 25.2474C14.3211 25.6698 13.6861 25.9375 13.0225 26.035C12.6454 26.0833 12.2837 26.0832 11.9342 26.0356C11.2679 25.9385 10.6324 25.6707 10.0918 25.2474L10.0681 25.2288L10.0433 25.2117C9.74661 25.0069 9.57523 24.726 9.53865 24.4619C9.56657 24.4402 9.61553 24.4101 9.69215 24.3779C9.76568 24.3471 9.8524 24.3196 9.94852 24.2967C9.9569 24.2953 9.97881 24.2918 10.0183 24.2873C10.0684 24.2815 10.1336 24.2754 10.2143 24.2693C10.3757 24.2572 10.581 24.2466 10.8199 24.2379C11.2969 24.2206 11.8843 24.2117 12.4759 24.2117C13.0674 24.2117 13.6548 24.2206 14.1318 24.2379C14.3707 24.2466 14.5761 24.2572 14.7374 24.2693C14.8181 24.2754 14.8833 24.2815 14.9334 24.2873C14.9727 24.2918 14.9946 24.2953 15.0031 24.2967C15.0992 24.3196 15.186 24.3471 15.2596 24.3779C15.3362 24.4101 15.3851 24.4402 15.4131 24.4619C15.3765 24.7262 15.2048 25.0081 14.9073 25.2147ZM19.164 4.16312L19.1662 4.16602C20.1267 5.40272 20.573 6.60804 20.573 8.62261V9.19927C20.573 10.1405 20.6979 10.9444 20.9891 11.6942C21.2794 12.4415 21.7128 13.0772 22.2515 13.7063L22.2514 13.7064L22.2607 13.7169C22.7835 14.3104 22.9998 15.1197 22.9998 16.1832C22.9998 17.1344 22.6924 18.0178 22.0858 18.7359C21.235 19.6398 20.0346 20.2179 18.8243 20.3179L18.8218 20.3182C16.7008 20.499 14.6073 20.6485 12.4952 20.6485C10.3653 20.6485 8.27756 20.5584 6.1971 20.321L6.1816 20.3192L6.16605 20.3179C4.95417 20.2178 3.75402 19.6396 2.90489 18.7362C2.29766 18.0174 1.98926 17.1337 1.98926 16.1832C1.98926 15.1212 2.20638 14.3111 2.72832 13.717C3.28876 13.0823 3.72609 12.4421 4.01408 11.6895C4.30206 10.9369 4.41744 10.1329 4.41744 9.19927V8.62261C4.41744 6.56508 4.91297 5.30004 5.93125 4.02938C7.47231 2.14742 9.9681 1 12.4347 1H12.5558C15.0809 1 17.6565 2.20724 19.164 4.16312Z"
                          stroke="black"
                          strokeWidth={2}
                        />
                      </svg>
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <div className="profile-imgs">
                      <a
                        onClick={handleClick}
                        className="nav-link profile-link">
                        <img
                          src={
                            data?.data?.image ??
                            "assets/images/icons/dummy-user.png"
                          }
                          alt=" profile"
                        />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
export default Header;
