import React from "react";
import PropTypes from "prop-types";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";

interface CountrySelectProps {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  labels: any;
  name: any;
  className: any;
  onblur: any;
}

const CountrySelect: React.FC<CountrySelectProps> = ({
  value,
  onChange,
  labels,
  name,
  className,
  onblur,
}) => {
  return (
    <select
      className={className}
      name={name}
      value={value || ""}
      onChange={(e) => onChange && onChange(e.target.value)}
      onBlur={onblur}>
      <option value="">{labels["ZZ"]}</option>
      {getCountries().map((country) => (
        <option key={country} value={country}>
          {labels[country]} +{getCountryCallingCode(country)}
        </option>
      ))}
    </select>
  );
};

CountrySelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default CountrySelect;
