import * as Yup from "yup";

const addBoxinitialValues = {
  box_id: "",
  total_keys: "",
};

//  validations schemas
const addBoxValidationSchema = Yup.object().shape({
  box_id: Yup.string()
    .required("Id is required*")
    .matches(
      /^[a-zA-Z0-9_]+$/,
      "Id can only contain letters, numbers, and underscores"
    ),
  total_keys: Yup.number().required("Keys is required*"),
});

export { addBoxinitialValues, addBoxValidationSchema };
