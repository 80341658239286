import * as Yup from "yup";

//initial values
const sendNotificationValues = {
  userType: "",
  title: "",
  message: "",
};

//  validations schemas
const sendNotificationSchema = Yup.object().shape({
  userType: Yup.string().required("User Type is required"),
  title: Yup.string().required("Title is required*"),
  message: Yup.string().trim().strict(true).required("Message is required*"),
});

export { sendNotificationValues, sendNotificationSchema };
