import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Pagination from "../../utils/pagination";
import Header from "../../components/content_wrapper/header";
import CountrySelect from "../../utils/countryCode";
import en from "react-phone-number-input/locale/en.json";
import { useUpdateOwner } from "../../hooks/ownerMangementApi/updateOwner";
import { useGetOwnerList } from "../../hooks/ownerMangementApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUpdateOwnerStatus } from "../../hooks/ownerMangementApi/updateStatus";
import { EditSvg, EyeSvg } from "../../utils/svgComponent";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import { useFormik } from "formik";
import {
  editOwnerInitialValues,
  editOwnerValidationSchema,
  resetPasswordSchema,
  resetPasswordValues,
} from "../../validations";
import { useOwnerResetPassword } from "../../hooks/auth/ownerResetPass";

const OwnersListingScreen: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSearch = searchParams.get("search") || "";
  const initialPage: any = parseInt(searchParams.get("page") || "1", 10);
  const initialLimit = parseInt(searchParams.get("limit") || "20", 10);
  const [finalValue, setFinalValue] = useState<string>(initialSearch);
  const [pageNumber, setPageNumber] = useState<any>(initialPage);
  const [countData, setCountData] = useState<any>(initialLimit);

  const [country, setCountry] = useState<any>("");
  const [getCode, setGetCode] = useState<any>(undefined);
  const [ownerId, setOwnerId] = useState();
  const [owner, setOwner] = useState<any>();
  const [showEye, setShowEye] = useState(false);
  const [showConfirmEye, setShowConfirmEye] = useState(false);

  const navigate = useNavigate();
  const { mutateAsync } = useUpdateOwnerStatus();
  const { mutateAsync: resetPassword, isLoading: resetLoading } =
    useOwnerResetPassword();
  const handleToggleClick = async (id: string, action: number) => {
    let Status = action === 1 ? 2 : 1;
    if (Status === 2) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't Suspend this Owner",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#72bcb8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Suspend",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res: any = await mutateAsync({
              ownerId: id,
              userStatus: Status,
            });
            if (res.success) {
              console.log(res);
            } else {
              console.error("Failed to update user status:", res.error);
            }
          } catch (error: any) {
            console.error("Unexpected error:", error);
          }
        }
      });
    } else {
      try {
        const res: any = await mutateAsync({
          ownerId: id,
          userStatus: Status,
        });
        if (res.success) {
          console.log(res);
        } else {
          console.error("Failed to update user status:", res.error);
        }
      } catch (error: any) {
        console.error("Unexpected error:", error);
      }
    }
  };

  const {
    data: OwnerListing,
    isError,
    isLoading,
  } = useGetOwnerList({
    page: finalValue?.length < 0 ? initialPage : pageNumber,
    limit: countData,
    search: initialSearch,
  });

  const handlePrevPage = () => {
    setPageNumber((prevPage: any) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPageNumber((nextPage: any) => nextPage + 1);
  };
  const handleRowLimitChange = (newValue: number) => {
    setCountData(newValue);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFinalValue(e.target.value);
  };
  const handleApplyClick = () => {
    setSearchParams({ search: finalValue, page: initialPage });
    setPageNumber(1);
  };

  const handleCancelClick = () => {
    setSearchParams({ search: "", page: "1" });
    setFinalValue("");
  };
  const handleClick = (id: any) => {
    navigate("/ownerdetail", {
      state: {
        id,
      },
    });
  };
  const { mutateAsync: updateOwner } = useUpdateOwner();
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldError,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: editOwnerInitialValues,
    validationSchema: editOwnerValidationSchema,
    onSubmit: async () => {
      try {
        const res: any = await updateOwner({
          id: owner?._id,
          name: values.username,
          countryCode: values.countryCode,
          phoneNumber: values.phoneNumber,
        });
        if (res?.status === true) {
          const closeModelButton: any = document.getElementById("close-btn");
          closeModelButton.click();
          toast.success(res?.message);
          setCountry("");
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
  });

  // get name from country code
  const callingCodeToCountry: any = {};
  getCountries().forEach(
    (country) =>
      (callingCodeToCountry[getCountryCallingCode(country)] = country)
  );
  const getCountryNameFromCallingCode = (callingCode: any, labels: any) =>
    callingCodeToCountry[callingCode] || null;

  const handleConvertCallingCode = (callingCode: any) =>
    getCountryNameFromCallingCode(callingCode, en);

  useEffect(() => {
    if (owner?.countryCode) {
      const CCode = handleConvertCallingCode(owner.countryCode);
      setCountry(CCode);
    }
  }, [owner?.countryCode]);
  //

  const handleCountryChange = (newCountry: any) => {
    if (country === "" || country === undefined) {
      setCountry("IN");
    }
    setCountry(newCountry);
  };
  useEffect(() => {
    if (country) {
      const code = getCountryCallingCode(country);
      setGetCode(code);
      if (code) {
        setFieldValue("countryCode", getCode);
      } else {
        setFieldError("countryCode", "country code is required*");
      }
    }
  }, [country, getCode]);

  const handleEditOwner = (id: any) => {
    setOwnerId(id);
  };
  useEffect(() => {
    if (OwnerListing?.data && ownerId) {
      const updatedOwner = OwnerListing.data.keyOwners.find(
        (owner: any) => owner._id === ownerId
      );
      setOwner(updatedOwner);
      setValues({
        countryCode: owner?.countryCode,
        phoneNumber: JSON.stringify(owner?.phoneNumber),
        username: owner?.name,
      });
    }
  }, [owner, ownerId]);
  // reset password
  const {
    values: reValues,
    touched: reTouches,
    errors: reErrors,
    handleBlur: reHandleBlur,
    handleChange: reHandleChange,
    handleSubmit: reHandleSubmit,
    resetForm: reset,
  } = useFormik({
    initialValues: resetPasswordValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async () => {
      try {
        const res: any = await resetPassword({
          user_id: ownerId,
          password: reValues.confirmPassword,
        });
        if (res?.status === true) {
          toast.success(res?.message);
          reset();
          const closeResetButton: any = document.getElementById("close-reset");
          closeResetButton.click();
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
  });
  return (
    <div className="main-wrapper">
      <Header title="Owners" />
      <div className="content-wrapper">
        <section className="main-section coach-section spacer">
          <div className="container">
            <div className="wrapper-card">
              <div className="row gy-3">
                <div className="col-xl-12">
                  <form>
                    <div className="row justify-content-between g-3">
                      <div className="col-lg-8 col-xl-8 col-xxl-6">
                        <div className="row align-items-center gy-3">
                          <div className="col-sm-7 col-xl-7">
                            <div className="form-block p-relative">
                              <input
                                type="search"
                                className="form-control"
                                id="search"
                                placeholder="Search by Name and Email"
                                value={finalValue}
                                onChange={handleSearchChange}
                              />
                              <span className="search-icon" />
                            </div>
                          </div>
                          <div className="col-12 col-sm-5 col-xl-5">
                            <button
                              type="submit"
                              className="btn primary-btn"
                              onClick={handleApplyClick}>
                              Apply
                            </button>
                            <button
                              type="submit"
                              className="btn secondary-btn"
                              onClick={handleCancelClick}>
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-xl-12">
                  <div className="sub-heading">
                    <h2>List of Owners</h2>
                  </div>
                  <div className="table-blk">
                    <table id="myTable" className="display border-0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Phone Number</th>
                          <th>Email</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {OwnerListing?.data?.keyOwners?.map(
                          (items: any, i: any) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <span className="table-profile">
                                    <span className="table-profileimg">
                                      <img
                                        src={
                                          items.image
                                            ? process.env.REACT_APP_BASE_URL +
                                              "/" +
                                              items.image
                                            : "assets/images/icons/dummy-user.png"
                                        }
                                        alt="user"
                                      />
                                    </span>
                                    <span className="table-username">
                                      {items?.name}
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  (+{items.countryCode}) {items?.phoneNumber}
                                </td>
                                <td>{items.email}</td>
                                <td>
                                  <span
                                    // className={
                                    //   items?.userStatus === 1
                                    //     ? "table-status st-active"
                                    //     : "table-status st-suspended"
                                    // }
                                    className={`c-pill 
                                      ${
                                        items?.userStatus === 1
                                          ? "c-pill--success"
                                          : "c-pill--danger"
                                      }`}>
                                    {items?.userStatus === 1
                                      ? "Active"
                                      : "Suspended"}
                                  </span>
                                </td>
                                <td>
                                  <span className="table-action">
                                    <a
                                      onClick={() => handleClick(items?._id)}
                                      className="table-eye">
                                      <EyeSvg />
                                    </a>
                                    <span
                                      className="table-check"
                                      key={items._id}>
                                      <input
                                        type="checkbox"
                                        id={`toggleEye${items._id}`}
                                        onChange={() =>
                                          handleToggleClick(
                                            items._id,
                                            items?.userStatus
                                          )
                                        }
                                        checked={
                                          items?.userStatus === 1 ? true : false
                                        }
                                      />
                                      <label
                                        htmlFor={`toggleEye${items._id}`}
                                      />
                                    </span>
                                    <a
                                      className="table-edit"
                                      data-bs-toggle="modal"
                                      data-bs-target="#editOwnerDetail"
                                      onClick={() =>
                                        handleEditOwner(items._id)
                                      }>
                                      <EditSvg />
                                    </a>
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                    {OwnerListing?.data?.keyOwners?.length === 0 ||
                    isError === true ? (
                      <div className="d-flex justify-content-center align-items-center my-3 sub-heading">
                        <h2 className="m-0">No data found</h2>
                      </div>
                    ) : null}
                    {isLoading && (
                      <div className="d-flex justify-content-center align-items-center my-3 sub-heading">
                        <h2 className="m-0">Loading..</h2>
                      </div>
                    )}
                    <Pagination
                      totalPages={OwnerListing?.data?.totalPage}
                      currentPage={pageNumber}
                      handlePrevPage={handlePrevPage}
                      handleNextPage={handleNextPage}
                      handleRowLimitChange={handleRowLimitChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        className="modal fade common-modal"
        id="editOwnerDetail"
        tabIndex={-1}
        aria-labelledby="editOwnerDetailLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                onClick={() => setCountry("")}
                type="button"
                id="close-btn"
                className="btn-close"
                data-bs-dismiss="modal"
              />
              <div className="common-box-body">
                <h2>Edit Owner</h2>

                <div className="common-modal-form">
                  <div className="d-flex justify-content-end">
                    <span
                      className="reset-password"
                      data-bs-toggle="modal"
                      data-bs-target="#secondModal">
                      Reset Password
                    </span>
                  </div>
                  <div className="common-modal-field">
                    <label>Username</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter username"
                      name="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                    />
                    {touched.username && errors.username && (
                      <p className="error">{errors.username}</p>
                    )}
                  </div>
                  <div className="common-modal-field">
                    <label>Country Code</label>
                    <CountrySelect
                      className="form-select"
                      name="countryCode"
                      labels={en}
                      value={country}
                      onChange={handleCountryChange}
                      onblur={handleBlur}
                    />
                    {/* <p>
                      Country Name from +91:{" "}
                      {handleConvertCallingCode(owner?.countryCode)}
                    </p> */}
                    {touched.countryCode && errors.countryCode && (
                      <p className="error">{errors.countryCode}</p>
                    )}
                  </div>
                  <div className="common-modal-field">
                    <label>Phone Number</label>
                    <input
                      className="phone-input form-control"
                      type="tel"
                      placeholder="Enter phone number"
                      maxLength={10}
                      name="phoneNumber"
                      onChange={handleChange}
                      value={values.phoneNumber}
                      onBlur={handleBlur}
                    />
                    {touched.phoneNumber && errors.phoneNumber && (
                      <p className="error">{errors.phoneNumber}</p>
                    )}
                  </div>
                </div>
                <div className="common-modal-btns">
                  <button
                    // data-bs-dismiss="modal"
                    // aria-label="Close"
                    className="btn primary-btn"
                    onClick={() => handleSubmit()}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* reset password Modal */}
      <div
        className="modal fade common-modal"
        id="secondModal"
        tabIndex={-1}
        aria-labelledby="editOwnerDetailLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#editOwnerDetail"
                id="close-reset"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => reset()}
              />
              <div className="common-box-body">
                <h2>Reset Password</h2>
                <div className="common-modal-form">
                  <div className="common-modal-field">
                    <label htmlFor="psw">Password</label>
                    <div className="p-relative">
                      <span className="eye">
                        <i
                          className={
                            !showEye
                              ? "fa-regular fa-eye"
                              : "fa-regular fa-eye-slash"
                          }
                          onClick={() => setShowEye(!showEye)}
                        />
                      </span>
                      <input
                        id="psw"
                        maxLength={10}
                        type={!showEye ? "password" : "text"}
                        className={"form-control"}
                        placeholder="Password"
                        value={reValues?.newPassword}
                        onChange={reHandleChange}
                        onBlur={reHandleBlur}
                        name="newPassword"
                      />
                      {reTouches.newPassword && reErrors.newPassword && (
                        <p className="error">{reErrors.newPassword}</p>
                      )}
                    </div>
                  </div>
                  <div className="common-modal-field">
                    <label htmlFor="cpsw">Confirm Password</label>
                    <div className="p-relative">
                      <span className="eye">
                        <i
                          className={
                            !showConfirmEye
                              ? "fa-regular fa-eye"
                              : "fa-regular fa-eye-slash"
                          }
                          onClick={() => setShowConfirmEye(!showConfirmEye)}
                        />
                      </span>
                      <input
                        id="cpsw"
                        maxLength={10}
                        type={!showConfirmEye ? "password" : "text"}
                        className={"form-control"}
                        placeholder="Confirm Password"
                        onChange={reHandleChange}
                        onBlur={reHandleBlur}
                        value={reValues?.confirmPassword}
                        name="confirmPassword"
                      />
                    </div>
                    {reTouches.confirmPassword && reErrors.confirmPassword && (
                      <p className="error">{reErrors.confirmPassword}</p>
                    )}
                  </div>
                </div>
                <div className="common-modal-btns">
                  <button
                    className="btn primary-btn"
                    onClick={() => reHandleSubmit()}>
                    {resetLoading ? "loading..." : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnersListingScreen;
