import { useGetActiveUsers } from "../../hooks/dashboard/getUserApi";
import CustomLaoder from "../../components/customLoader";
import Header from "../../components/content_wrapper/header";
import { useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Link } from "react-router-dom";
const data = [
  {
    name: "Jan",
    uv: 40,
    amt: 2400,
  },
  {
    name: "Feb",
    uv: 30,
    amt: 2210,
  },
  {
    name: "Mar",
    uv: 20,
    amt: 2290,
  },
  {
    name: "Apr",
    uv: 70,
    amt: 2000,
  },
  {
    name: "May",
    uv: 18,
    amt: 2181,
  },
  {
    name: "Jun",
    uv: 23,
    amt: 2500,
  },
  {
    name: "Jul",
    uv: 34,
    amt: 2100,
  },
  {
    name: "Aug",
    uv: 50,
    amt: 2000,
  },
  {
    name: "Sep",
    uv: 90,
    amt: 1100,
  },
  {
    name: "Oct",
    uv: 40,
    amt: 4400,
  },
  {
    name: "Nov",
    uv: 74,
    amt: 6200,
  },
  {
    name: "Dec",
    uv: 39,
    amt: 3000,
  },
];

const Dashboard: React.FC = () => {
  const { data: activeUsres, isLoading } = useGetActiveUsers();
  const [selectedPeriod, setSelectedPeriod] = useState("week");

  const handlePeriodChange = (period: any) => {
    setSelectedPeriod(period);
  };
  const usersData = activeUsres?.data;
  const tabs = ["day", "week", "month", "year"];
  const timePeriods = ["day", "week", "month", "year"];
  const cardData = [
    {
      bg: "bg-yellow",
      img1: "assets/images/icons/user-sm-icon.svg",
      day: "Number of Active Users",
      count: usersData?.activeUsers,
      to: "/ownerlisting",
      img2: "assets/images/icons/curve-yellow.svg",
    },
    {
      bg: "bg-purple",
      img1: "assets/images/icons/usercheck-sm-icon.svg",
      day: "Number of Subscribed Users",
      count: usersData?.subscribedUsers,
      to: "/ownerlisting",
      img2: "assets/images/icons/curve-purple.svg",
    },
    {
      bg: "bg-orange",
      img1: "assets/images/icons/key-sm-icon.svg",
      day: "Number of Registered Keys",
      count: usersData?.keyRegistered,
      to: "/keymanagementlisting",
      img2: "assets/images/icons/curve-red.svg",
    },
    {
      bg: "bg-primary",
      img1: "assets/images/icons/key-sm-icon.svg",
      day: "Number of Booked Keys",
      count: usersData?.totalBookedKeys,
      to: "/keymanagementlisting",
      img2: "assets/images/icons/curve-green.svg",
    },
    {
      bg: "bg-lgt-navy",
      img1: "assets/images/icons/key-sm-icon.svg",
      day: "Total Registered Key Owners",
      count: usersData?.totalKeyOwners,
      to: "/ownerlisting",

      img2: "assets/images/icons/curve-navy.svg",
    },
    {
      bg: "bg-pink",
      img1: "assets/images/icons/user-sm-icon.svg",
      day: "Total Registered Key Host",
      count: usersData?.totalKeyHosts,
      to: "/keyhostlisting",

      img2: "assets/images/icons/curve-pink.svg",
    },
  ];
  return (
    <div className="main-wrapper">
      <Header title="Dashboard" />
      <div className="content-wrapper">
        <CustomLaoder loader={isLoading}>
          <section className="main-section hero-section spacer">
            <div className="container">
              <div className="row gy-3">
                <div className="col-lg-12">
                  <div className="dashboard-blk">
                    <ul
                      className="nav nav-pills mb-4"
                      id="pills-tab"
                      role="tablist">
                      {tabs.map((tab, i) => (
                        <li className="nav-item" role="presentation" key={i}>
                          <button
                            className={`nav-link ${tab === "day" && "active"}`}
                            id={`pills-${tab}-tab`}
                            data-bs-toggle="pill"
                            data-bs-target={`#pills-${tab}`}
                            type="button"
                            role="tab"
                            aria-controls={`pills-${tab}`}
                            aria-selected="false">
                            {tab.charAt(0).toUpperCase() + tab.slice(1)}
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-day"
                        role="tabpanel"
                        aria-labelledby="pills-day-tab"
                        tabIndex={0}>
                        <div className="total-card-blk">
                          {cardData.map((data, index) => (
                            <Link
                              to={data?.to}
                              className="total-card-inner"
                              key={index}>
                              <div className="total-card-text">
                                <span className={`card-icon ${data.bg}`}>
                                  <img src={data.img1} alt="icon" />
                                </span>
                                <span className="card-days">{data.day}</span>
                                <span className="card-count">{data.count}</span>
                              </div>

                              <div className="total-card-graph">
                                <img src={data.img2} alt="Graph" />
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* <div className="row mt-4">
                      <div className="col-xl-8">
                        <div className="graph-card">
                          <div className="sub-heading flex-box mt-0">
                            <h2>Number of Key Owner</h2>
                            <div className="select-period-field">
                              <ul
                                className="nav nav-pills mb-3"
                                id="pills-tab"
                                role="tablist">
                                {timePeriods.map((period: any) => (
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className={`nav-link ${
                                        period === selectedPeriod
                                          ? "active"
                                          : ""
                                      }`}
                                      id={`pills-nk-${period}-tab`}
                                      data-bs-toggle="pill"
                                      data-bs-target={`#pills-nk-${period}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`pills-nk-${period}`}
                                      aria-selected={period === selectedPeriod}
                                      onClick={() =>
                                        handlePeriodChange(period)
                                      }>
                                      {period.charAt(0).toUpperCase() +
                                        period.slice(1)}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <div className="tab-content" id="pills-tabContent">
                            {timePeriods.map((period) => (
                              <div
                                className={`tab-pane fade ${
                                  period === selectedPeriod ? "show active" : ""
                                }`}
                                id={`pills-nk-${period}`}
                                role="tabpanel"
                                aria-labelledby={`pills-nk-${period}-tab`}
                                tabIndex={0}>
                                <div className="graph-blk">
                                 
                                  <LineChart
                                    className="inner-Wrapper"
                                    width={1000}
                                    height={350}
                                    data={data}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}>
                                    <CartesianGrid
                                      horizontal={false}
                                      stroke="#DEDCF5"
                                      strokeDasharray="2 2"
                                      strokeLinecap="round"
                                      strokeWidth={2}
                                    />
                                    <XAxis
                                      dataKey="name"
                                      axisLine={false}
                                      tickLine={false}
                                    />
                                    <YAxis
                                      padding={{ bottom: 15 }}
                                      type="number"
                                      domain={[0, 100]}
                                      allowDataOverflow
                                      // stroke="#DEDCF5"
                                      tickCount={5}
                                      axisLine={false}
                                      tickLine={false}
                                    />
                                    <Tooltip />
                                    <Line
                                      strokeWidth={5}
                                      type="natural"
                                      dataKey="uv"
                                      stroke="#72BCB8"
                                      activeDot={{ r: 10 }}
                                    />
                                  </LineChart>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CustomLaoder>
      </div>
    </div>
  );
};

export default Dashboard;
