import { useQuery } from "react-query";
import { QueryReturnType } from "../../services/networking.service";
import ENDPOINTS from "../../services/endPoints";

export const useGetPlans = (): QueryReturnType<any> => {
  return useQuery<never, never, any>([ENDPOINTS.GET_PLANS], {
    notifyOnChangeProps: [
      "data",
      "error",
      "refetch",
      "isRefetching",
      "isSuccess",
      "isLoading",
    ],
  });
};
