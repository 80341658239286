import { useMutation, useQueryClient } from "react-query";
import { Auth } from "../../interface";
import { mutatePutFn } from "../../services/networking.service";
import ENDPOINTS from "../../services/endPoints";

export const usePlanStatus = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutatePutFn(ENDPOINTS.PLAN_STATUS, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};
