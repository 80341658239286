import { useQuery } from "react-query";
import { QueryReturnType } from "../../services/networking.service";
import ENDPOINTS from "../../services/endPoints";

interface IProps {
  page: string;
  limit: string;
  search: string;
  fromDate: any;
  toDate: any;
}

export const useGetTransactionLogs = ({
  page,
  limit,
  search,
  fromDate,
  toDate,
}: IProps): QueryReturnType<any> => {
  return useQuery<never, never, any>(
    [
      ENDPOINTS.GET_TRANSACTION_LOGS,
      {
        page: page,
        limit: limit,
        search: search,
        fromDate: fromDate,
        toDate: toDate,
      },
    ],
    {
      notifyOnChangeProps: [
        "data",
        "error",
        "refetch",
        "isRefetching",
        "isSuccess",
        "isLoading",
      ],
    }
  );
};
