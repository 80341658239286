import * as Yup from "yup";

const addCouponInitialvalues = {
  title: "",
  start_date: "",
  end_date: "",
  plan_type: "",
  subscription_plan: "",
  coupon_amount: "",
  description: "",
  users_type: "",
  limit: "",
  coupon_type: "",
};
const validationSchema = Yup.object().shape({
  title: Yup.string().trim().strict(true).required("Title is required*"),
  start_date: Yup.date().required("Required"),
  end_date: Yup.date().required("Required*"),
  plan_type: Yup.string().required("Plan type is required"),
  // subscription_plan: Yup.string().required("Subscription plan is required"),
  subscription_plan: Yup.string().when("plan_type", ([plan_type], schema) => {
    if (plan_type === "1") return Yup.string().required("Field is required");
    return schema;
  }),

  // coupon_amount: Yup.number().required("Coupon amount is required"),
  coupon_amount: Yup.string().when("coupon_type", ([coupon_type]) => {
    if (coupon_type === "2")
      return Yup.number().max(100).required("Coupon amount is required");
    else return Yup.number().required("Coupon amount is required");
  }),
  description: Yup.string()
    .trim()
    .strict(true)
    .required("Description is required"),
  users_type: Yup.string().required("User type is required"),
  // limit: Yup.number().required("Limit is required"),
  limit: Yup.string().when("users_type", ([users_type], schema) => {
    if (users_type === "0")
      return Yup.string().required("Limit is required conditional");
    return schema;
  }),
  coupon_type: Yup.string().required("Coupon type is required"),
});

export { addCouponInitialvalues, validationSchema };
