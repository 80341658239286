import React from "react";
import moment from "moment";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { HistorySvg } from "../../utils/svgComponent";
import Header from "../../components/content_wrapper/header";
import { useGetKeyDetails } from "../../hooks/keyManagement/keyDetail";
import { useGetKeyHistory } from "../../hooks/keyManagement/keyHistoty";
import { useCancelSubscription } from "../../hooks/keyManagement/cancelSubscription";
import { useGetKeyHistoryCollection } from "../../hooks/keyManagement/keyHistoryCollection";

const KeyManagementDetailScreen: React.FC = () => {
  const location = useLocation();
  const data = location.state;

  const { data: keyDetail } = useGetKeyDetails({ id: data.id });
  const keyDetailData = keyDetail?.data?.keyDetails;

  //Key History
  const { data: keyHistory } = useGetKeyHistory({ id: data.id });
  const { data: historyCollection } = useGetKeyHistoryCollection({
    id: data.id,
  });

  const navigate = useNavigate();
  const { mutateAsync } = useCancelSubscription();
  const handleToggleClick = async (id: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't Cancel this Key Subscription",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#72bcb8",
      cancelButtonColor: "#d33",
      cancelButtonText: "Back",
      confirmButtonText: "Yes, Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res: any = await mutateAsync({
            key_id: id,
          });
          if (res?.statusCode === 200) {
            toast.success(res?.message);
          }
        } catch (err: any) {
          toast.error(err?.response?.data?.message);
        }
      }
    });
  };
  return (
    <div className="main-wrapper">
      <Header title="Key detail" />
      <div className="content-wrapper">
        <section className="main-section profile-section spacer ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mb-4">
                  <button
                    className="btn primary-btn m-0 view-history-btn"
                    onClick={() => navigate(-1)}>
                    <i className="fa-solid fa-chevron-left" />{" "}
                    <span className="m-lg-1">Back</span>
                  </button>
                </div>

                <div className="ribbon">
                  <span
                    className={`ribbon-box ribbon-theme-${
                      keyDetailData?.status === 0
                        ? "yellow"
                        : keyDetailData?.status === 1
                        ? "green"
                        : keyDetailData?.status === 2
                        ? "red"
                        : "red"
                    }`}>
                    <span className="ribbon-content">
                      {keyDetailData?.status === 0
                        ? "Pending"
                        : keyDetailData?.status === 1
                        ? "Available"
                        : keyDetailData?.status === 2
                        ? "In-Use"
                        : "Deleted"}
                    </span>
                  </span>
                  <div className="wrapper-card">
                    <div className="row gy-3 gx-5">
                      <div className="col-lg-6">
                        <div className="sub-heading flex-box mt-0">
                          <h2 className="mb-0">Key detail</h2>
                        </div>
                        <div className="info-card key-detail">
                          <div className="info-flex-detail">
                            <div className="info-detail">
                              <h2 className="h4">Key name</h2>
                              <p className="mb-0">{keyDetailData?.name}</p>
                            </div>
                            <div className="info-detail">
                              <h2 className="h4">Owner name</h2>
                              <p className="mb-0">
                                {keyDetailData?.owner_name}
                              </p>
                            </div>
                            <div className="info-detail">
                              <h2 className="h4">Phone number</h2>
                              <p className="mb-0">
                                (+{keyDetailData?.countryCode}){" "}
                                {keyDetailData?.phoneNumber}
                              </p>
                            </div>
                            <div className="info-detail">
                              <h2 className="h4">Email address</h2>
                              <p className="mb-0">{keyDetailData?.email}</p>
                            </div>
                            <div className="info-detail">
                              <h2 className="h4">Address</h2>
                              <p className="mb-0">{keyDetailData?.address}</p>
                            </div>

                            <div className="info-detail justify-content-between">
                              <h2 className="h4">Subscription</h2>
                              <div className="d-flex gap-3">
                                {keyDetailData?.stripe_subscription_status ===
                                  false && (
                                  <div>
                                    <b>Cancelled</b>
                                    <p>
                                      {moment
                                        .unix(
                                          keyDetailData?.subscription_cancel_string
                                        )
                                        .format("L")}
                                    </p>
                                  </div>
                                )}

                                <div>
                                  <b>Expire</b>
                                  <p className="mb-0">
                                    {data.status === 3
                                      ? moment
                                          .unix(
                                            keyDetailData?.key_deletion_String
                                          )
                                          .format("L")
                                      : moment
                                          .unix(
                                            keyDetailData?.key_subscription_expiryString
                                          )
                                          .format("L")}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {keyDetailData &&
                              keyDetailData.subscription_added_string && (
                                <div className="info-detail">
                                  <h2 className="h4">
                                    Subscription Purchase Date
                                  </h2>
                                  <p className="mb-0">
                                    {moment
                                      .unix(
                                        keyDetailData.subscription_added_string
                                      )
                                      .format("L")}
                                  </p>
                                </div>
                              )}
                            <div className="d-flex justify-content-between">
                              <button
                                className="text-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#keyAllDetail">
                                Show more detail
                              </button>
                              {data.status !== 3 &&
                                keyDetailData?.stripe_subscription_status !==
                                  false && (
                                  <button
                                    className="text-btn text-danger"
                                    onClick={() =>
                                      handleToggleClick(keyDetailData?._id)
                                    }>
                                    Cancel Subscription
                                  </button>
                                )}
                            </div>
                            {/* <div className="info-txt-detail">
                        <h2 className="h4">Store info</h2>
                        <p className="mb-0">{keyDetailData?.store_info}</p>
                      </div> */}
                          </div>
                        </div>
                        <div className="sub-heading flex-box mt-4">
                          <h2 className="mb-0">Key Box detail</h2>
                        </div>
                        <div className="info-card key-detail">
                          {keyDetailData?.box_detail?.length > 0 ? (
                            keyDetailData?.box_detail?.map(
                              (item: any, index: number) => (
                                <div className="info-flex-detail" key={index}>
                                  <div className="info-detail">
                                    <h2 className="h4">Box ID</h2>
                                    <p className="mb-0">{item.box_id}</p>
                                  </div>
                                  <div className="info-detail">
                                    <h2 className="h4">Key Slot No.</h2>
                                    <p className="mb-0">
                                      {item.key_slot_number}
                                    </p>
                                  </div>
                                  <div className="info-detail">
                                    <h2 className="h4">Key Ring ID</h2>
                                    <p className="mb-0">{item.keyring_id}</p>
                                  </div>
                                  <div className="info-detail justify-content-between">
                                    <h2 className="h4">Status</h2>
                                    <span
                                      className={`c-pill ${
                                        item.link_status === "linked"
                                          ? "c-pill--success"
                                          : "c-pill--warning"
                                      } mb-0`}>
                                      {item.link_status}
                                    </span>
                                  </div>
                                </div>
                              )
                            )
                          ) : (
                            <p className="mb-0">
                              No key box details available.
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="sub-heading flex-box mt-0">
                          <h2 className="mb-0">Key host detail</h2>
                          <div className="flex-end-box">
                            {/* <span
                              className={
                                data.status !== 3
                                  ? "key-host-status"
                                  : "table-status st-suspended"
                              }
                              data-status={data.status}>
                              {data.status === 0
                                ? "Pending"
                                : data.status === 1
                                ? "Available"
                                : data.status === 2
                                ? "In-Use"
                                : "Deleted"}
                            </span> */}
                            <div className="d-flex gap-2">
                              <button
                                className="btn primary-btn me-0 view-history-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#keyHistory">
                                <span>
                                  <HistorySvg />
                                </span>{" "}
                                Key History
                              </button>
                              <button
                                className="btn primary-btn me-0 view-history-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#keyHistoryCollection">
                                <span>
                                  <HistorySvg />
                                </span>{" "}
                                Code History
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="info-card key-detail">
                          <div className="info-flex-detail">
                            <div className="info-detail">
                              <h2 className="h4">Key host Name</h2>
                              <p className="mb-0">{keyDetailData?.host_name}</p>
                            </div>
                            <div className="info-detail">
                              <h2 className="h4">Email</h2>
                              <p className="mb-0">
                                {keyDetailData?.host_email}
                              </p>
                            </div>
                            <div className="info-detail">
                              <h2 className="h4">Phone number</h2>
                              <p className="mb-0">
                                (+{keyDetailData?.countryCode}){" "}
                                {keyDetailData?.phone_number}
                              </p>
                            </div>
                            <div className="info-detail">
                              <h2 className="h4">Address</h2>
                              <p className="mb-0">
                                {keyDetailData?.host_address}
                              </p>
                            </div>
                            {/* <div className="info-detail">
                        <h2 className="h4">Location</h2>
                        <p className="mb-0">N/A</p>
                      </div> */}
                            <div className="info-detail">
                              <h2 className="h4">Store name</h2>
                              <p className="mb-0">
                                {keyDetailData?.store_name}
                              </p>
                            </div>
                            <div className="info-txt-detail">
                              <h2 className="h4">Store information</h2>
                              <p className="mb-0">
                                {keyDetailData?.store_info}
                              </p>
                            </div>

                            <div className="info-txt-detail">
                              <h2 className="h4">Hours of opration</h2>
                              <span className="hrs-opt">
                                {keyDetailData?.active_times?.map(
                                  (time: any) => (
                                    <span>
                                      {time?.day} -
                                      {time?.closed === false &&
                                      time.start_time !== "" ? (
                                        <>
                                          {moment
                                            .utc(time.start_time, "HH:mm")
                                            .format("LT")}{" "}
                                          -{" "}
                                          {moment
                                            .utc(time.end_time, "HH:mm")
                                            .format("LT")}
                                        </>
                                      ) : (
                                        "Closed"
                                      )}
                                    </span>
                                  )
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Modal section */}
      <div
        className="modal fade key-modal"
        id="keyHistory"
        tabIndex={-1}
        aria-labelledby="keyHistoryLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="key-historymodal-body">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
                <h2>Key history</h2>
                <div className="table-blk modal-table-blk">
                  <table id="myTable" className="display border-0">
                    <thead>
                      <tr>
                        <th>Code number</th>
                        <th>Key collected date</th>
                        <th>Key returned date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {keyHistory?.data?.map((data: any) => (
                        <tr>
                          <td>{data?.code_number}</td>
                          <td>
                            {data?.key_collected_date !== null
                              ? moment
                                  .unix(data?.key_collected_date)
                                  .format("YYYY-MM-DD")
                              : ""}
                          </td>
                          <td>
                            {data?.key_returned_date === null
                              ? ""
                              : moment
                                  .unix(data?.key_returned_date)
                                  .format("YYYY-MM-DD")}
                          </td>
                          <td>
                            <span
                              className={
                                data?.current_active_code_status === true
                                  ? "table-status st-active"
                                  : "table-status st-suspended"
                              }>
                              {data?.current_active_code_status === true
                                ? "Active"
                                : "In-Active"}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {keyHistory?.data?.length === 0 ? (
                    <div className="d-flex justify-content-center align-items-center my-3">
                      <h2 className="m-0">No data found</h2>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*key history collection modal*/}
      <div
        className="modal fade key-modal"
        id="keyHistoryCollection"
        tabIndex={-1}
        aria-labelledby="keyHistoryLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="key-historymodal-body">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
                <h2>Key History Collection</h2>
                <div className="table-blk modal-table-blk">
                  <table id="myTable" className="display border-0">
                    <thead>
                      <tr>
                        <th>Code number</th>
                        <th>Code issue date</th>
                        <th>Code expire</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {historyCollection?.data?.map((data: any) => (
                        <tr>
                          <td>{data?.code_number}</td>
                          <td>
                            {data?.code_issue_date !== null
                              ? moment
                                  .unix(data?.code_issue_date)
                                  .format("YYYY-MM-DD")
                              : ""}
                          </td>
                          <td>
                            {data?.code_expiry_date === 0
                              ? "Non-expired date"
                              : moment
                                  .unix(data?.code_expiry_date)
                                  .format("YYYY-MM-DD")}
                          </td>
                          <td>
                            <span
                              className={
                                data?.active_status === true
                                  ? "table-status st-active"
                                  : "table-status st-suspended"
                              }>
                              {data?.active_status === true
                                ? "Active"
                                : "In-Active"}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {keyHistory?.data?.length === 0 ? (
                    <div className="d-flex justify-content-center align-items-center my-3">
                      <h2 className="m-0">No data found</h2>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* All Details modal */}
      <div
        className="modal fade key-modal"
        id="keyAllDetail"
        tabIndex={-1}
        aria-labelledby="keyHistoryLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="key-historymodal-body">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
                <div className="common-box-body">
                  <h2>{keyDetailData?.name} detail</h2>
                  <div className="common-modal-form"></div>
                  <div className="table-blk modal-table-blk">
                    <table id="myTable" className="display border-0">
                      <thead>
                        <tr>
                          <th>Plan Name</th>
                          <th>Plan Period</th>
                          <th>Actual Amount</th>
                          <th>Paid Amount</th>
                          <th>Subscription ID</th>
                          <th>Expairy</th>
                        </tr>
                      </thead>
                      <tbody>
                        {keyDetailData?.transaction_logs?.map((item: any) => (
                          <tr>
                            <td>{item?.planName}</td>
                            <td>{item?.planType}</td>
                            <td>
                              {`$${
                                item?.actualAmount ||
                                item?.actualAmount !== undefined
                                  ? item?.actualAmount
                                  : "0"
                              }`}
                            </td>
                            <td>
                              ${item?.paidAmount ? item?.paidAmount : "0"}
                            </td>
                            <td>
                              <span>{item?.stripe_subscription_Id}</span>
                            </td>
                            <td>
                              {moment
                                .unix(
                                  keyDetailData?.key_subscription_expiryString
                                )
                                .format("l")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {keyDetailData?.transaction_logs?.length === 0 ? (
                      <div className="d-flex justify-content-center align-items-center my-3">
                        <h2 className="m-0">No data found</h2>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyManagementDetailScreen;
