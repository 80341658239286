import React, { useEffect, useState } from "react";
import Header from "../../components/content_wrapper/header";
import { useGetContent } from "../../hooks/contentManagementApi/getContent";
import { useUpdateContent } from "../../hooks/contentManagementApi/updateContent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomLaoder from "../../components/customLoader";
import { useFormik } from "formik";
import { contentValues, contentValuesSchema } from "../../validations";
import { toast } from "react-toastify";
import { EditSvg } from "../../utils/svgComponent";
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    ["link", "image", "formula"],

    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }],
    [{ color: [] }, { background: [] }, { align: [] }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ font: [] }],

    ["clean"],
  ],
};

const formats = [
  "background",
  "font",
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];

const ContentManagement: React.FC = () => {
  const [contentID, setContentID] = useState<any>("");
  const { data, isError, isLoading } = useGetContent();
  const { mutateAsync } = useUpdateContent();
  const handleEditorState = (data: any) => {
    setFieldValue("description", data);
  };

  const handleContentChange = async (id: string) => {
    setContentID(id);
  };

  const handleTextChange = (content: any) => {
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(content, "text/html");
    const stringLength =
      parsedDocument.documentElement.textContent?.length || 0;

    if (stringLength === 0) {
      setFieldValue("description", "");
    } else {
      setFieldValue("description", content);
    }
  };

  const { values, touched, errors, handleSubmit, setFieldValue, resetForm } =
    useFormik({
      initialValues: contentValues,
      validationSchema: contentValuesSchema,
      onSubmit: async () => {
        try {
          const res: any = await mutateAsync({
            id: contentID === "" ? data?.data[0]?._id : contentID,
            description: values.description,
          });
          if (res?.status === true) {
            toast.success(res?.message);
            resetForm();
            const closeModelButton: any = document.getElementById("close-btn");
            closeModelButton.click();
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.message);
        }
      },
    });

  const tabs = [
    {
      id: "pills-0-tab",
      target: "#pills-0",
      label: "Terms & Conditions",
      index: 0,
    },
    {
      id: "pills-1-tab",
      target: "#pills-1",
      label: "Privacy Policy",
      index: 1,
    },
    {
      id: "pills-2-tab",
      target: "#pills-2",
      label: "About Us",
      index: 2,
    },
  ];
  return (
    <div className="main-wrapper">
      <Header title="Content" />
      <div className="content-wrapper">
        <CustomLaoder loader={isLoading}>
          <section className="main-section spacer">
            <div className="container">
              <div className="wrapper-card">
                <div className="row gy-3">
                  <div className="col-xl-12">
                    <ul className="content-tabs" id="pills-tab" role="tablist">
                      {tabs.map((tab, index) => (
                        <li
                          key={tab.id}
                          className="nav-item"
                          role="presentation">
                          <button
                            className={`nav-link ${
                              index === 0 ? "active" : ""
                            }`}
                            id={tab.id}
                            data-bs-toggle="pill"
                            data-bs-target={tab.target}
                            type="button"
                            role="tab"
                            aria-controls={tab.target}
                            aria-selected={index === 0}
                            onClick={() =>
                              handleContentChange(data?.data[tab.index]?._id)
                            }>
                            {tab.label}
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      {data?.data?.length > 0 ? (
                        data?.data?.map((item: any, index: any) => {
                          return (
                            <div
                              key={index}
                              className={`tab-pane fade ${
                                index === 0 ? "show active" : ""
                              }`}
                              id={`pills-${index}`}
                              role="tabpanel"
                              aria-labelledby={`pills-${index}-tab`}
                              tabIndex={0}>
                              <div className="tab-content-wrapper">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h2>{item.title}</h2>
                                  <button
                                    className="btn primary-btn me-0 view-history-btn my-3"
                                    data-bs-toggle="modal"
                                    data-bs-target="#changeContentModal"
                                    onClick={() =>
                                      handleEditorState(item.description)
                                    }>
                                    <span className="m-lg-1">Edit Content</span>
                                  </button>
                                </div>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="d-flex justify-content-center align-items-center my-3 sub-heading">
                          <h2 className="m-0">No data found</h2>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CustomLaoder>
        <div
          className="modal fade common-modal addkeyhost-modal"
          id="changeContentModal"
          tabIndex={-1}
          aria-labelledby="changeContentLabel"
          aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  id="close-btn"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
                <div className="common-box-body">
                  <h2>Edit Content</h2>
                  <div className="common-modal-form">
                    <ReactQuill
                      id="description"
                      theme="snow"
                      modules={modules}
                      style={{ fontSize: "15px" }}
                      formats={formats}
                      placeholder="write your content ...."
                      value={values?.description}
                      onChange={handleTextChange}
                    />
                    {touched.description && errors.description && (
                      <p className="error">{errors.description}</p>
                    )}
                  </div>
                  <div className="common-modal-btns">
                    <button
                      className="btn primary-btn me-0"
                      onClick={() => handleSubmit()}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentManagement;
