import { useQuery } from "react-query";
import { QueryReturnType } from "../../services/networking.service";
import ENDPOINTS from "../../services/endPoints";
interface IProps {
  id: any;
}

export const useGetKeyHistoryCollection = ({
  id,
}: IProps): QueryReturnType<any> => {
  return useQuery<never, never, any>(
    [ENDPOINTS.KEY_HISTORY_COLLECTION, { keyId: id }],
    {
      notifyOnChangeProps: [
        "data",
        "error",
        "refetch",
        "isRefetching",
        "isSuccess",
        "isLoading",
      ],
    }
  );
};
