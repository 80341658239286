import React from "react";

type Props = {
  children: React.ReactNode;
  handleSubmit: () => void;
  handleCloseModal: () => void;
  modalTitle: string;
  open: boolean;
};

const CouponModal = ({
  children,
  handleSubmit,
  handleCloseModal,
  modalTitle,
  open,
}: Props) => {
  if (!open) {
    return null;
  } else {
    return (
      <div
        style={{ backgroundColor: "#0006" }}
        className="modal fade common-modal show d-block">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                onClick={handleCloseModal}
              />
              <div className="common-box-body">
                <h2>{modalTitle}</h2>
                {children}
                <div className="common-modal-btns">
                  <button
                    className="btn primary-btn"
                    type="button"
                    onClick={() => handleSubmit()}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CouponModal;
