import React from "react";
import Header from "../../components/content_wrapper/header";

const Notification: React.FC = () => {
  return (
    <div className="main-wrapper">
      <Header title="Notification" />
      <div className="content-wrapper">
        <section className="main-section notification-section spacer">
          <div className="container">
            <div className="row gy-3">
              <div className="col-xl-12">
                <div className="notification-card new-ntf">
                  <div className="ntf-user-profile">
                    <img src="assets/images/icons/user-02.png" alt="user" />
                  </div>
                  <div className="ntf-details">
                    <h2 className="h4">john Smith</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Obcaecati adipisci quisquam magni enim similique facilis
                      dolor, quidem eveniet quaerat harum aut possimus aliquam
                      tempora natus maiores, fugit dolore corrupti. Harum.
                    </p>
                  </div>
                  <span className="ntf-time">2 min ago</span>
                </div>
                <div className="notification-card">
                  <div className="ntf-user-profile">
                    <img src="assets/images/icons/user-02.png" alt="user" />
                  </div>
                  <div className="ntf-details">
                    <h2 className="h4">john Smith</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Obcaecati adipisci quisquam magni enim similique facilis
                      dolor, quidem eveniet quaerat harum aut possimus aliquam
                      tempora natus maiores, fugit dolore corrupti. Harum.
                    </p>
                  </div>
                  <span className="ntf-time">2 min ago</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Notification;
