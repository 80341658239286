/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageContext = createContext<any>(null);

interface IProps {
    children: React.ReactNode
}

export const LanguageProvider: React.FC<IProps> = ({ children }) => {

    // translation from i18n
    const { i18n } = useTranslation();

    const changeLanguage: any = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    return (
        <LanguageContext.Provider value={{ changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguageContext = () => useContext(LanguageContext);