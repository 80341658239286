import React, { useContext, useState } from "react";
import userGlobalConfig from "../../constants/globalConfig";
import { AuthContext } from "../../context/authContext";
import { useFormik } from "formik";
import { LogininitialValues, loginValidationSchema } from "../../validations";
import { toast } from "react-toastify";
import { useAdminLogin } from "../../hooks/auth/loginApi";

const LoginScreen: React.FC = () => {
  const [showEye, setShowEye] = useState(false);
  const { setisAuthenticated } = useContext(AuthContext);
  const { mutateAsync } = useAdminLogin();

  const { values, touched, errors, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: LogininitialValues,
      validationSchema: loginValidationSchema,
      onSubmit: async () => {
        try {
          const res: any = await mutateAsync({
            email: values.email,
            password: values.password,
          });
          if (res?.status === true) {
            setisAuthenticated(true);
            localStorage.setItem(
              userGlobalConfig.ADMIN_DETAILS,
              JSON.stringify(res?.data)
            );
            localStorage.setItem(userGlobalConfig.TOKEN, res?.token);
            toast.success(res?.message);
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.message);
        }
      },
    });

  return (
    <section className="login-section">
      <div className="container-fluid g-0">
        <div className="row g-0">
          <div className="col-md-12">
            <div className="login-main-blk">
              <div className="login-form-blk">
                <div className="login-heading">
                  <h1>login</h1>
                </div>
                <form>
                  <div className="form-group">
                    <label htmlFor="email">Your email</label>
                    <input
                      className={
                        errors.email && touched.email
                          ? "form-control border border-danger"
                          : "form-control"
                      }
                      id="email"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email && (
                      <p className="error">{errors.email}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="psw">Password</label>

                    <div className="p-relative">
                      <span className="eye">
                        <i
                          className={
                            !showEye
                              ? "fa-regular fa-eye"
                              : "fa-regular fa-eye-slash"
                          }
                          onClick={() => setShowEye(!showEye)}
                        />
                      </span>
                      <input
                        id="psw"
                        type={!showEye ? "password" : "text"}
                        className={
                          errors.email && touched.email
                            ? "form-control border border-danger"
                            : "form-control"
                        }
                        placeholder="Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>

                    {errors.password && touched.password && (
                      <p className="error">{errors.password}</p>
                    )}
                  </div>
                  <div className="login-btn-blk">
                    <button
                      type="button"
                      className="btn primary-btn"
                      onClick={() => handleSubmit()}>
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginScreen;
