import { useQuery } from "react-query";
import { QueryReturnType } from "../../services/networking.service";
import ENDPOINTS from "../../services/endPoints";
import { Auth } from "../../interface";

export const useGetBoxes = (): QueryReturnType<any> => {
  return useQuery<Auth, never, any>([ENDPOINTS.GET_BOXES], {
    notifyOnChangeProps: [
      "data",
      "error",
      "refetch",
      "isRefetching",
      "isSuccess",
      "isLoading",
    ],
  });
};
