import React from "react";
import Header from "../../components/content_wrapper/header";
import { useSendNotification } from "../../hooks/otherApi/send_notification";
import { useFormik } from "formik";
import {
  sendNotificationSchema,
  sendNotificationValues,
} from "../../validations/sendNotificationValidation";
import { toast } from "react-toastify";

const Send_Notification: React.FC = () => {
  const { mutateAsync, isLoading } = useSendNotification();

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: sendNotificationValues,
    validationSchema: sendNotificationSchema,
    onSubmit: async () => {
      try {
        const res: any = await mutateAsync({
          title: values.title,
          message: values.message,
          user_type: values.userType,
        });
        if (res?.status === true) {
          toast.success(res?.message);
          resetForm();
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
  });

  return (
    <div className="main-wrapper">
      <Header title="Send Notification" />
      <div className="content-wrapper">
        <section className="main-section send-section spacer">
          <div className="container">
            <div className="row">
              <div className="col-xl-6">
                <form>
                  <div className="change-main-wrapper">
                    <div className="send-notification mt-0">
                      <div className="row gy-5">
                        <div className="col-lg-6">
                          <div className="form-block p-relative">
                            <label>User Type</label>
                            <select
                              name="userType"
                              className="form-select"
                              value={values.userType}
                              onChange={handleChange}
                              onBlur={handleBlur}>
                              <option value="" disabled>
                                Select Type
                              </option>
                              <option value="1">Owner</option>
                              <option value="2">Keyhost</option>
                              <option value="3">Both</option>
                            </select>
                            {touched.userType && errors.userType && (
                              <p className="error">{errors.userType}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-block p-relative">
                            <label>Title</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Title"
                              name="title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.title}
                            />
                            {touched.title && errors.title && (
                              <p className="error">{errors.title}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-block p-relative">
                            <label>Description</label>
                            <textarea
                              className="form-control"
                              placeholder="Enter your message"
                              name="message"
                              value={values.message}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.message && errors.message && (
                              <p className="error">{errors.message}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-xxl-12">
                          <div className="profile-btns text-center">
                            <button
                              type="button"
                              disabled={isLoading}
                              className="btn primary-btn me-0"
                              onClick={() => handleSubmit()}>
                              {isLoading ? "Sending.." : "Send"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Send_Notification;
