import React, { useEffect, useState } from "react";
import Header from "../../components/content_wrapper/header";
import { useGetPlans } from "../../hooks/subscriptionManagement/getPlans";
import { useAddPlan } from "../../hooks/subscriptionManagement/addPlan";
import {
  addMonthValidationSchema,
  addPlanValues,
  combinedValidationSchema,
} from "../../validations/plansValidation";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useEditPrice } from "../../hooks/subscriptionManagement/editPrice";
import SubscriptionModal from "./subscriptionModal";
import { usePlanStatus } from "../../hooks/subscriptionManagement/planStatus";
import { DeleteSvg } from "../../utils/svgComponent";
import Swal from "sweetalert2";
import { useDeletePlan } from "../../hooks/subscriptionManagement/deletePlan";

const SubscriptionManagementScreen: React.FC = () => {
  const [planType, setPlanType] = useState("");
  const [modalData, setModalData] = useState({
    open: false,
    modalTitle: "",
    modalType: "",
    data: {
      id: "",
      price: "",
    },
  });

  const { data: data, isError } = useGetPlans();
  const { mutateAsync } = useAddPlan();
  const { mutateAsync: updateMutation } = useEditPrice();
  const { mutateAsync: updateStatus } = usePlanStatus();
  const { mutateAsync: deletePlan } = useDeletePlan();

  let completeInitialValue;
  let completeInitialSchema;

  if (planType === "month") {
    completeInitialValue = addPlanValues;
    completeInitialSchema = addMonthValidationSchema;
  } else {
    completeInitialValue = addPlanValues;
    completeInitialSchema = combinedValidationSchema;
  }
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: completeInitialValue,
    validationSchema: completeInitialSchema,
    onSubmit: async () => {
      if (modalData.modalType === "Edit") {
        try {
          const res: any = await updateMutation({
            plan_id: modalData?.data?.id,
            price: values.price,
          });
          if (res?.status === true) {
            toast.success(res?.message);
            setModalData({ ...modalData, open: false });
            resetForm();
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.message);
        }
      }
      if (modalData.modalType === "Add") {
        try {
          const res: any = await mutateAsync({
            title: values.title,
            timePeriod: values.monthType,
            price: values.price,
            type: values.type,
          });
          if (res?.status === true) {
            toast.success(res?.message);
            setModalData({ ...modalData, open: false });
            resetForm();
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.message);
        }
      } else {
        return null;
      }
    },
  });

  const handleModalOpen = (e: any, data?: any) => {
    if (e.target.title === "Add") {
      setModalData({
        ...modalData,
        modalTitle: "Add Plan",
        open: true,
        modalType: e.target.title,
        data: { id: "", price: "" },
      });
    }
    if (e.target.title === "Edit") {
      setModalData({
        ...modalData,
        modalTitle: "Edit Price",
        open: true,
        modalType: e.target.title,
        data: { id: data?._id, price: data.price },
      });
      setValues({
        price: data?.price,
        title: "title",
        type: "type",
        monthType: "",
      });
    } else return null;
  };

  const handleCloseModal = () => {
    setModalData({
      ...modalData,
      modalTitle: "",
      open: false,
    });
    resetForm();
  };

  const returnChild = () => {
    if (modalData.modalType === "Add") {
      return (
        <div className="common-modal-form">
          <div className="common-modal-field">
            <div className="common-modal-field">
              <label>Select the time period for the plan (month or year)</label>
              <select
                name="type"
                className="form-select"
                onChange={(e) => {
                  handleChange(e);
                  setPlanType(e.target.value);
                }}
                value={values.type}
                onBlur={handleBlur}>
                <option value="" disabled>
                  Select Type
                </option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </select>
              {touched.type && errors.type && (
                <p className="error">{errors.type}</p>
              )}
            </div>

            <div className="common-modal-field">
              <label>Enter the number of months or years for the plan</label>
              <input
                placeholder="Enter the number of months/years"
                className="form-control"
                type="number"
                name="monthType"
                value={values.monthType}
                onChange={(e) => handleChange(e)}
                onBlur={handleBlur}
              />
              {touched.monthType && errors.monthType && (
                <p className="error">{errors.monthType}</p>
              )}
            </div>
          </div>

          <div className="common-modal-field">
            <label>Enter Plan Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Plan Name"
              name="title"
              onChange={handleChange}
              value={values.title}
              onBlur={handleBlur}
            />
            {touched.title && errors.title && (
              <p className="error">{errors.title}</p>
            )}
          </div>
          <div className="common-modal-field">
            <label>Enter Price</label>
            <input
              type="number"
              max="999"
              className="form-control"
              placeholder="Enter Price"
              name="price"
              value={values.price}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.price && errors.price && (
              <p className="error">{errors.price}</p>
            )}
          </div>
        </div>
      );
    }
    if (modalData.modalType === "Edit") {
      return (
        <div className="common-modal-form">
          <div className="common-modal-field">
            <label>Update Price</label>
            <input
              type="text"
              name="price"
              className="form-control"
              placeholder="Enter Price"
              value={values.price}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.price && errors.price && (
              <p className="error">{errors.price}</p>
            )}
          </div>
        </div>
      );
    }
  };
  // update status
  const handleToggleClick = async (id: string, action: any) => {
    let Status = action === true ? false : true;
    try {
      await updateStatus({
        plan_id: id,
        plan_status: Status,
      });
    } catch (error: any) {
      console.error("Unexpected error:", error);
    }
  };
  // delete Plan
  const handleDeleteCoupon = (id: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't Delete this Plan",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#72bcb8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deletePlan({ plan_id: id });
        toast.success("Delete Successfully");
      }
    });
  };
  return (
    <div className="main-wrapper">
      <Header title="Subscriptions" />
      <div className="content-wrapper">
        <section className="main-section profile-section spacer">
          <div className="container">
            <div className="row gy-3">
              <div className="col-xl-12">
                <div className="addsub-plan">
                  <button
                    title="Add"
                    className="btn primary-btn me-0"
                    onClick={(e) => handleModalOpen(e)}>
                    + Add New Plan
                  </button>
                </div>
                <div className="subscription-card-wrapper">
                  <div className="subscription-flex-card">
                    {data?.data?.map((items: any) => (
                      <div className="subscription-card text-capitalize">
                        <button
                          className="del-sub-card-btn"
                          onClick={() => handleDeleteCoupon(items?._id)}>
                          <DeleteSvg />
                        </button>
                        <h2>{items.title}</h2>
                        <span className="subscription-price">{`£${
                          items.price
                        }/ ${
                          items.timePeriod === null
                            ? "" +
                              items?.type.charAt(0).toUpperCase() +
                              items?.type.slice(1)
                            : items.timePeriod +
                              items?.type.charAt(0).toUpperCase() +
                              items?.type.slice(1)
                        }`}</span>
                        <div className="subscription-action-btn">
                          <button
                            title="Edit"
                            className="btn primary-btn me-0"
                            onClick={(e) => handleModalOpen(e, items)}>
                            Edit Price
                          </button>
                          <button
                            className={
                              items?.suspended === false
                                ? "btn black-btn"
                                : "btn primary-btn me-0"
                            }
                            onClick={() =>
                              handleToggleClick(items._id, items?.suspended)
                            }>
                            {items?.suspended === false ? "Suspend" : "Active"}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {data?.data?.length === 0 || isError === true ? (
                  <div className="d-flex justify-content-center align-items-center my-3 sub-heading">
                    <h2 className="m-0">No data found</h2>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </div>
      <SubscriptionModal
        handleSubmit={() => handleSubmit()}
        modalTitle={modalData.modalTitle}
        open={modalData.open}
        handleCloseModal={handleCloseModal}>
        {returnChild()}
      </SubscriptionModal>
    </div>
  );
};

export default SubscriptionManagementScreen;
