import React, { Fragment, useState } from "react";
import { useGetOwnerDetail } from "../../hooks/ownerMangementApi/ownerDetails";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/content_wrapper/header";
import { EyeSvg } from "../../utils/svgComponent";

const OwnersDetailScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const { data: GetDetail } = useGetOwnerDetail({
    ownerId: data?.id,
  });
  const OwnerData = GetDetail?.data?.ownerDetails;
  const handleClick = (id: string) => {
    navigate("/keymanagementdetail", {
      state: {
        id,
      },
    });
  };

  return (
    <div className="main-wrapper">
      <Header title="Owner Details" />
      <div className="content-wrapper">
        <section className="main-section profile-section">
          <div className="container">
            <button
              className="btn primary-btn me-0 view-history-btn my-3"
              onClick={() => navigate(-1)}>
              <i className="fa-solid fa-chevron-left" />{" "}
              <span className="m-lg-1">Back</span>
            </button>

            <div className="wrapper-card">
              <div className="row gy-3">
                <div className="col-xl-12">
                  <div className="info-card">
                    <div className="row justify-content-between gy-3">
                      <div className="col-6 col-sm-6 col-lg-4 col-xl-2">
                        <div className="info-detail">
                          <h2 className="h4">Username</h2>
                          <p className="mb-0">{OwnerData?.name}</p>
                        </div>
                      </div>
                      {/* <div className="col-6 col-sm-6 col-lg-4 col-xl-3">
                        <div className="info-detail">
                          <h2 className="h4">Location</h2>
                          <p className="mb-0">108, GM Road, New York</p>
                        </div>
                      </div> */}
                      <div className="col-6 col-sm-6 col-lg-4 col-xl-2">
                        <div className="info-detail">
                          <h2 className="h4">Total no of keys owned</h2>
                          <p className="mb-0">{OwnerData?.total_keys}</p>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-4 col-xl-2">
                        <div className="info-detail">
                          <h2 className="h4">Phone number</h2>
                          <p className="mb-0">
                            (+{OwnerData?.countryCode}) {OwnerData?.phoneNumber}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-4 col-xl-2">
                        <div className="info-detail">
                          <h2 className="h4">Email address</h2>
                          <p className="mb-0">{OwnerData?.email}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="sub-heading my-3">
                    <h2>Keys details</h2>
                  </div>
                  <div className="table-blk">
                    <table id="myTable" className="display border-0">
                      <thead>
                        <tr>
                          <th>Key name</th>
                          <th>Box ID</th>
                          <th>Cubicle Location</th>
                          <th>Key host location</th>
                          <th>Subscription validity</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {GetDetail?.data?.keyList?.map(
                          (item: any, index: number) => (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>{item.box_id}</td>
                              <td>{item.box_location}</td>
                              <td>{item.key_host_location}</td>
                              <td>
                                <span
                                  // className={
                                  //   item.isSubscriptionActive === true
                                  //     ? "table-status st-active"
                                  //     : "table-status st-suspended"
                                  // }
                                  className={`c-pill 
                                    ${
                                      item.isSubscriptionActive === true
                                        ? "c-pill--success"
                                        : "c-pill--danger"
                                    }`}>
                                  {item.isSubscriptionActive === true
                                    ? "Active"
                                    : "Suspended"}
                                </span>
                              </td>
                              <td>
                                <span className="table-action">
                                  <a
                                    className="table-eye"
                                    onClick={() => handleClick(item._id)}>
                                    <EyeSvg />
                                  </a>
                                </span>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                    {GetDetail?.data?.keyList?.length === 0 ? (
                      <div className="d-flex justify-content-center align-items-center my-3 sub-heading">
                        <h2 className="m-0">No data found</h2>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OwnersDetailScreen;
