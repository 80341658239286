import { useQuery } from "react-query";
import { QueryReturnType } from "../../services/networking.service";
import { Auth } from "../../interface";
import ENDPOINTS from "../../services/endPoints";
interface IProps {
  page: string;
  limit: string;
  id: string;
}

export const useGetBoxDetail = ({
  page,
  limit,
  id,
}: IProps): QueryReturnType<any> => {
  return useQuery<Auth, never, any>(
    [ENDPOINTS.GET_BOX_DETAIL, { id: id, page: page, limit: limit }],
    {
      notifyOnChangeProps: [
        "data",
        "error",
        "refetch",
        "isRefetching",
        "isSuccess",
        "isLoading",
      ],
    }
  );
};
