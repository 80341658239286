import { useQuery } from "react-query";
import { QueryReturnType } from "../../services/networking.service";
import ENDPOINTS from "../../services/endPoints";

interface IProps {
  id: any;
}

export const useGetKeyHostDetails = ({ id }: IProps): QueryReturnType<any> => {
  return useQuery<never, never, any>(
    [ENDPOINTS.GET_KEY_HOST_DETAILS, { id: id }],
    {
      notifyOnChangeProps: [
        "data",
        "error",
        "refetch",
        "isRefetching",
        "isSuccess",
        "isLoading",
      ],
    }
  );
};
