import { useQuery } from "react-query"
import { QueryReturnType } from "../../services/networking.service"
import ENDPOINTS from "../../services/endPoints"

export const useGetActiveUsers = (): QueryReturnType<any> => {
    return useQuery<never, never, any>(
        [ENDPOINTS.ACTIVE_USERS],
        {
            notifyOnChangeProps: ['data', 'error', 'refetch', 'isRefetching', 'isSuccess', 'isLoading'],
        }
    )
}