import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { sideBarArray } from "../../seeds";
import { useLocation, useNavigate } from "react-router-dom";
import userGlobalConfig from "../../constants/globalConfig";
import { AuthContext } from "../../context/authContext";
import { toast } from "react-toastify";
import { SidebarContext } from "../../context/sidebarContext";
import Swal from "sweetalert2";

type Props = {};

const Sidebar: React.FC<Props> = () => {
  // const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const { t } = useTranslation();
  const nav = useNavigate();
  const { setisAuthenticated } = useContext(AuthContext);
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);

  const location = useLocation();

  const handleNavigation = (path: string) => {
    nav(path);
  };

  const isActive = (pathname: string) => {
    return location.pathname === pathname;
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't log out",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#72bcb8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Log out!",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem(userGlobalConfig.TOKEN);
        setisAuthenticated(false);
        toast.success("Logout Successfully");
      }
    });
  };

  return (
    <Fragment>
      {isSidebarOpen && (
        <div className="side-navbar">
          <button className="closeBtn" onClick={toggleSidebar}>
            <i className="fa-solid fa-times" />
          </button>
          <ul className="nav flex-column position-relative">
            <li className="nav-item sidebar-logo">
              <a className="navbar-brand text-white">
                <img src="assets/images/icons/logo.svg" alt="Logo" />
              </a>
              <div className="wlecome-text-blk">
                <span>Hi Admin!!</span>
                <span>Welcome to Administrative Panel!! </span>
              </div>
            </li>
            {sideBarArray?.map((items, i) => {
              return (
                <li
                  className="nav-item sidebar-link"
                  key={i}
                  onClick={() => {
                    handleNavigation(items.path);
                  }}>
                  <a
                    className={`nav-link ${
                      isActive(items.path) ? "active" : ""
                    }`}>
                    {t(items?.name)}
                  </a>
                </li>
              );
            })}
          </ul>
          <ul className="list-unstyled">
            <li className="nav-item sidebar-link" onClick={handleLogout}>
              <a className="nav-link">Logout</a>
            </li>
          </ul>
        </div>
      )}
    </Fragment>
  );
};

export default Sidebar;
